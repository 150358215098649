<script setup lang="ts">
// types
import type {
  Entity,
  Nullable,
  ScheduleChangePayloadItem,
  Subject
} from '@revolutionprep/types'
import type { FetchError } from 'ofetch'

// utilities
import { toEntities } from '@revolutionprep/utils'

// sentry
import * as Sentry from '@sentry/vue'

// composables
import { useEnrollmentWizard } from '@/composables/enrollmentWizard'
import { usePage } from '@/composables/page'

// stores
import { useGlobalStore } from '@/store/global'

/**
 * page metadata
 * ==================================================================
 */
definePageMeta({
  name: 'EnrollmentWizardStep4Page',
  layout: 'enrollment-wizard',
  middleware: ['validate-route-roles']
})

/**
 * nuxt app
 * ==================================================================
 */
const { $actor, $pusher } = useNuxtApp()

/**
 * state
 * ==================================================================
 */
const isLoading = ref(false)
const title = ref('Enrollment Wizard | Step 4')
const titleIcon = ref('')
const scheduleChangeItem = ref<Nullable<ScheduleChangePayloadItem>>(null)
const selectedTutorId = ref<Nullable<number>>(null)
const showDialogTutorProfile = ref(false)

/**
 * composables
 * ==================================================================
 */
const {
  doIntervalChange,
  isVisible
} = useCalendarPusher()

const {
  affiliateReservation,
  affiliateReservationNumber,
  brandId,
  companyPhone,
  error,
  isProcessing,
  onIntersectSidebarBottom,
  onIntersectSidebarTop,
  sidebarCssClasses,
  step1Url,
  student,
  studentAvailabilitiesJson,
  studentFirstName,
  studentFullName,
  subjectId,
  toolbarCssClasses,
  toNextStep,
  toPreviousStep,
  tutorPackageTranscript,
  tutorPackageTranscriptBrand,
  tutorPackageTranscriptBrandSubjects,
  tutorPackageTranscriptId,
  tutorPackageTranscriptIncludes,
  tutorPackageTranscriptSubject
} = useEnrollmentWizard()

const { doHandleError } = useErrorHandler()

usePage(title)

/**
 * stores
 * ==================================================================
 */
const globalStore = useGlobalStore()
const { pageTitle, pageTitleIcon } = storeToRefs(globalStore)

const tutorStore = useTutorStore()
const { prioritizedTutors, tutor } = storeToRefs(tutorStore)

const tutorPackageTranscriptStore = useTutorPackageTranscriptStore()

/**
 * computed
 * ==================================================================
 */
const allTutors = computed(() => {
  return [...currentTutors.value, ...otherTutors.value]
})

const isSubjectTaughtByAnyTutor = computed(() => {
  if (otherTutors.value.length) {
    return true
  }
  return Boolean(currentTutors.value.find((tutor) => {
    return tutor.subjects?.find((tutorSubject) => {
      return tutorSubject.id === tutorPackageTranscriptSubject.value?.id
    })
  }))
})

const channelName = computed(() => {
  return tutor.value
    ? `public-revolution-user-${tutor.value.userId}`
    : ''
})

const currentTutors = computed(() => {
  return tutorPackageTranscript.value?.enrollment?.tutors || []
})

const duration = computed(() => {
  return tutorPackageTranscript.value?.subjectDuration || null
})

const otherTutors = computed(() => {
  return prioritizedTutors.value
})

const showCardSelectedTutor = computed(() => {
  return Boolean(
    allTutors.value.length &&
    isSubjectTaughtByAnyTutor.value &&
    selectedTutorId.value &&
    tutor.value
  )
})

const tutorHasSubject = computed(() => {
  return tutorPackageTranscriptSubject.value?.id
    ? Boolean(tutorSubjectsEntities.value[
      tutorPackageTranscriptSubject.value?.id
    ])
    : false
})

const tutorPackageTranscriptSubjectName = computed(() => {
  return tutorPackageTranscriptSubject.value?.name || ''
})

const tutorSubjects = computed(() => {
  return tutor.value?.subjects || []
})

const tutorSubjectsEntities = computed(() => {
  return tutor.value
    ? toEntities(tutor.value.subjects || []) as Entity<Subject>
    : {}
})

const tutorSubjectsWithBrandSubjects = computed(() => {
  const intersectingSubjects: Subject[] = []

  tutorPackageTranscriptBrandSubjects.value.forEach((brandSubject) => {
    const tutorTeachesSubject = tutorSubjects.value.find((subject) => {
      return subject.id === brandSubject.id
    })
    if (tutorTeachesSubject) {
      intersectingSubjects.push(brandSubject)
    }
  })

  return intersectingSubjects
})

/**
 * watchers
 * ==================================================================
 */
watch(selectedTutorId, async (newTutorId, oldTutorId) => {
  if (selectedTutorId.value && newTutorId !== oldTutorId) {
    isLoading.value = true
    tutor.value = null
    await fetchTutor(selectedTutorId.value)
    isLoading.value = false
  }
})

watch(channelName, (newVal, oldVal) => {
  shutdownPusher(oldVal)
  setupPusher(newVal)
})

/**
 * methods
 * ==================================================================
 */
function doSelectTutorId (tutorId: Nullable<number>) {
  selectedTutorId.value = tutorId
}

async function doSubmit () {
  try {
    isProcessing.value = true
    await tutorPackageTranscriptStore.update(
      tutorPackageTranscriptId.value,
      {
        tutorPackageTranscript: {
          tutorId: selectedTutorId.value
        }
      },
      {
        params: {
          include: tutorPackageTranscriptIncludes.value
        },
        method: 'PATCH'
      }
    )
    toNextStep(4)
  } catch (errorResponse) {
    const errorObject =
      await doHandleError(errorResponse as FetchError | Error)
    if (errorObject?.statusCode === 500) {
      error.value = errorObject
    }
    isProcessing.value = false
  }
}

async function fetchPrioritizedTutors () {
  await tutorStore.getPrioritizedTutors({
    params: {
      acceptNewStudents: true,
      brandId: brandId.value,
      page: 1,
      per: 10,
      sessionDuration: duration.value,
      subjectId: subjectId.value,
      studentId: student.value?.id
    }
  })
}

async function fetchTutor (tutorId: number) {
  await tutorStore.show(
    tutorId,
    {
      params: {
        include: 'subjects,personal_profile.educations.degrees'
      }
    }
  )
}

function setDefaultTutorId () {
  if (tutorPackageTranscript.value?.tutor?.id) {
    doSelectTutorId(tutorPackageTranscript.value.tutor.id)
  } else if (allTutors.value.length) {
    doSelectTutorId(allTutors.value[0].id || null)
  }
}

function setupPusher (channelName: string) {
  if (channelName) {
    $pusher.bindEvent(
      'schedule-change',
      channelName,
      (data: { item: ScheduleChangePayloadItem }) => {
        const { item } = data
        // if item is visible in calendar dates
        if (item.endsAt && isVisible(item.startsAt, item.endsAt)) {
          scheduleChangeItem.value = item
        }
      }
    )
  }
}

function shutdownPusher (channelName: string) {
  if (channelName) {
    $pusher.unbindEvent('schedule-change', channelName)
  }
}

function toggleDialogTutorProfile () {
  showDialogTutorProfile.value = !showDialogTutorProfile.value
}

/**
 * page setup
 * ==================================================================
 */
pageTitle.value = title.value
pageTitleIcon.value = titleIcon.value

// set stepper
$actor.core.storage.setUniversal('enrollmentWizardStep', 4)
$actor.core.storage.setUniversal(
  'enrollmentWizardHeading',
  'Step 4: Which of our tutors looks best?'
)

/**
 * data fetching
 * ==================================================================
 */
const { pending } = useLazyAsyncData('enrollment-wizard-tutor-page', async () => {
  try {
    await fetchPrioritizedTutors()
    setDefaultTutorId()
    if (selectedTutorId.value) {
      await fetchTutor(selectedTutorId.value)
      setupPusher(channelName.value)
    }
  } catch (errorResponse) {
    const errorObject =
      await doHandleError(errorResponse as FetchError | Error)
    if (errorObject?.statusCode === 500) {
      error.value = errorObject
    }
  } finally {
    if (!allTutors.value.length) {
      // If no tutors are suggested, report info to Sentry
      Sentry.withScope((scope) => {
        scope.setTag('subject', tutorPackageTranscriptSubject.value?.name)
        scope.setTag('studentId', student.value?.id)
        scope.addAttachment({
          filename: `student-${student.value?.id}-availabilities-json.json`,
          data: JSON.stringify(student.value?.availabilitiesJson)
        })
        Sentry.captureException(
          new Error('Enrollment Wizard - No tutor suggestions')
        )
      })
    }
  }
})

/**
 * lifecycle hooks
 * ==================================================================
 */
onBeforeUnmount(() => {
  shutdownPusher(channelName.value)
})
</script>

<template>
  <div>
    <v-container class="px-0">
      <v-row v-if="pending">
        <div
          class="d-flex flex-column justify-space-between"
          style="width: 100%;"
        >
          <p class="loading-text text-overline text-textdarkgrey text-center pa-4">
            Hang tight, we are finding the best tutor for you...
          </p>
          <v-slide-group
            class="mb-4"
            show-arrows="always"
          >
            <v-slide-group-item
              v-for="loader in 16"
              :key="`skeleton-loader-tutor-card-${loader}`"
            >
              <div class="pa-2">
                <v-card
                  width="180"
                  min-height="220"
                >
                  <v-card-text style="height: 220px;">
                    <v-row style="height: 100%;">
                      <v-col
                        cols="12"
                        class="d-flex justify-center align-end"
                      >
                        <v-skeleton-loader type="avatar" />
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-center"
                      >
                        <v-skeleton-loader
                          type="text"
                          width="100"
                        />
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </div>
            </v-slide-group-item>
          </v-slide-group>
          <v-skeleton-loader
            width="100%"
            type="image@3"
          />
        </div>
      </v-row>
      <v-row v-else-if="error">
        <v-col>
          <lazy-r-error-display
            :error="error"
            :home-url="step1Url"
          />
        </v-col>
      </v-row>
      <template v-else>
        <v-row>
          <v-col
            v-if="allTutors.length && isSubjectTaughtByAnyTutor"
            cols="12"
            md="9"
            lg="10"
          >
            <v-alert
              class="mb-4"
              type="info"
              variant="tonal"
            >
              We’ve made a few recommendations for a tutor for
              {{ studentFirstName }}. Click on the below tutor pictures to see
              their upcoming availability and bio. All our tutors have been
              vetted and mentored to deliver great outcomes. You will select
              your session times after you complete your enrollment. This
              calendar is just a preview of the tutor’s availability.
            </v-alert>
            <LazySlideGroupTutor
              class="mb-6"
              :all-tutors="allTutors"
              :current-tutors="currentTutors"
              :other-tutors="otherTutors"
              :brand="tutorPackageTranscriptBrand"
              :subject="tutorPackageTranscriptSubject"
              :value="selectedTutorId"
              :view-bio-option="true"
              @select="doSelectTutorId"
              @show-tutor-bio="toggleDialogTutorProfile"
            />
            <LazyCardTutorAvailability
              v-if="selectedTutorId"
              class="mb-6"
              default-calendar-view="timeGridThreeWeek"
              :schedule-change-item="scheduleChangeItem"
              :student-availabilities-json="studentAvailabilitiesJson"
              :tutor-id="selectedTutorId"
              @interval-change="doIntervalChange"
            />
          </v-col>
          <v-col
            v-else
            cols="12"
            md="9"
            lg="10"
          >
            <LazyAlertContactUs
              v-if="affiliateReservationNumber"
              :affiliate-reservation-number="affiliateReservationNumber"
              :company-phone="companyPhone"
              reason-for-contact="no-tutor"
              :selected-subject-name="tutorPackageTranscriptSubjectName"
            >
              <template #title>
                Oh no!
              </template>
              <template #message>
                We're sorry, we cannot find any recommendations for a tutor for
                {{ studentFirstName }} at this time.
              </template>
            </LazyAlertContactUs>
          </v-col>
          <v-col
            cols="12"
            md="3"
            lg="2"
          >
            <div v-intersect="onIntersectSidebarTop" />
            <div :class="sidebarCssClasses">
              <LazyCardReservationDetails
                v-if="affiliateReservation"
                class="mb-2"
                :affiliate-reservation="affiliateReservation"
                reservation-title="Back-Up Care Reservation"
                :student-full-name="studentFullName"
              />
              <LazyCardSelectedTutor
                v-if="!isLoading && showCardSelectedTutor && tutor"
                class="mb-2 mt-6"
                :tutor="tutor"
              >
                <template #title>
                  <div class="text-overline">
                    Selected Tutor
                  </div>
                </template>
              </LazyCardSelectedTutor>
              <v-skeleton-loader
                v-else-if="isLoading"
                class="skeleton-selected-tutor mb-2 mt-6"
                type="image"
                height="106"
              />
              <div v-intersect="onIntersectSidebarBottom" />
              <LazyToolbarEnrollmentWizard
                :class="toolbarCssClasses('smAndDown')"
                :has-padding="Boolean(toolbarCssClasses('smAndDown'))"
                :is-flat="!Boolean(toolbarCssClasses('smAndDown'))"
                :is-next-button-disabled="!allTutors.length || !selectedTutorId || isProcessing || !tutorHasSubject"
                next-button-text="Next"
                @back="toPreviousStep(4)"
                @next="doSubmit"
              />
            </div>
          </v-col>
        </v-row>
      </template>
      <lazy-r-dialog-tutor-profile
        v-if="tutor"
        :show="showDialogTutorProfile"
        :tutor="tutor"
        :highlighted-subject-id="subjectId"
        :show-tutor-tier="false"
        :tutor-subjects="tutorSubjectsWithBrandSubjects"
        @toggle-dialog="toggleDialogTutorProfile"
      />
    </v-container>
  </div>
</template>

<style>
.v-skeleton-loader__avatar {
  height: 6rem;
  width: 6rem;
}
</style>

<style scoped>
.loading-text {
  font-size: 1.25rem !important;
}
</style>
